
// Async load the Vue 3 APIs we need from the Vue ESM
import {computed, defineComponent} from 'vue';
import useFormMachine from './store';
import Form from "./Form.vue";
import Success from "./Success.vue";

export default defineComponent({
  components: { Form },
  setup() {
    const { state } = useFormMachine();

    const activeComponent = computed(() => {
      return state.value.matches('final') ? Success : Form;
    })

    return {
      state,
      activeComponent
    }
  }
});
