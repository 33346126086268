import {assign, interpret, Machine, send} from "xstate";
import {useService} from "@xstate/vue";
import axios from "axios";

const formMachine = Machine({
        id: 'formMachine',
        initial: 'idle',
        context: {
            formData: {},
        },
        states: {
            idle: {
                on: {
                    'SUBMIT': 'submitting',
                }
            },
            submitting: {
                entry: [
                    assign({
                        formData: (_, event) => event.data
                    })
                ],
                invoke: {
                    src: {
                        type: 'formSubmit'
                    },
                    onDone: {
                      actions: send('FINALIZE', { delay: 300 })
                    },
                    onError: 'failed'
                },
            },
            failed: {},
            submitted: {
                after: {
                    350: 'final'
                }
            },
            final: {
                type: 'final',
            }
        },
        on: {
            'FINALIZE': 'submitted',
        }
    }, {
        services: {
            formSubmit: (_, event) => {
                return axios.post('https://aquanautic.herweg.co/api/contact/store', { ...event.data});
            },
        }
    },
);

const service = interpret(formMachine).start();

export default function useFormMachine() {
    return useService(service);
}
