<template>
  <div
    class="max-w-4xl mx-auto py-9 px-4 md:py-16 md:px-16 lg:px-24  bg-white flex flex-col justify-center items-center text-center"
  >
    <SuccessGraphic class="w-44 mb-12" />

    <h1 class="text-4xl font-semibold mb-4">
      Vielen Dank für Ihre Anfrage
    </h1>
    <p class="text-2xl mb-12">
      Wir erstellen ein unverbindliches Angebot für Sie.
      Dabei ermitteln wir immer die besten Preise für ihr gewünschtes Urlaubspaket.
    </p>

    <a
      href="/"
      class="bg-brand-400 text-primary-500 rounded font-bold px-6 py-4 no-underline"
    >
      Zurück zur Startseite
    </a>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import SuccessGraphic from "./SuccessGraphic.vue";
import useFormMachine from "./store";
import axios from "axios";
import qs from 'qs';

export default defineComponent({
  name: 'Success',
  components: {SuccessGraphic},
})
</script>
