import { reactive, ref } from 'vue';

export function useMq(mediaQueriesArg?: any) {
    const mediaQueries = {
        mobile: '(max-width: 639px)',
        sm: '(min-width: 640px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 1024px)',
        xl: '(min-width: 1280px)',
        '2xl': '(min-width: 1440px)',
        ...mediaQueriesArg,
    };
    const mq = ref(0);
    const MQMAP = reactive({});

    mq.value = Object.keys(mediaQueries).reduce((obs, current) => {
        const mql = window.matchMedia(mediaQueries[current]);

        // eslint-disable-next-line
        // @ts-ignore
        mql.addListener((e) => (obs[current] = e.matches));
        // eslint-disable-next-line no-param-reassign
        // @ts-ignore
        obs[current] = mql.matches;
        MQMAP[current] = mql;

        return obs;
    }, {});

    return {
        mq,
    };
}
