<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill="#193A55"
      d="M24 45c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21z"
    />
    <path
      stroke="#FFD800"
      stroke-width="4"
      d="M13 24.184l9.24 7.935L34.207 15"
    />
  </svg>
</template>

<script>
export default {
  name: 'Checkmark'
}
</script>
