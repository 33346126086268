<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 24 24"
    xml:space="preserve"
  >
    <path d="M10.621 6.611a1.86 1.86 0 00-.471 1.431l.851 8.508a.5.5 0 00.498.45h1a.5.5 0 00.498-.45l.851-8.508a1.86 1.86 0 00-.471-1.431c-.705-.777-2.053-.777-2.756 0zM11.998 18a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0011.998 18zm10.587.861L15.071 1.824A3.454 3.454 0 0011.998 0c-1.301 0-2.45.682-3.091 1.861L1.43 18.824a3.471 3.471 0 00.063 3.463A3.472 3.472 0 004.503 24h14.991a3.472 3.472 0 003.01-1.713 3.451 3.451 0 00.081-3.426zm-.941 2.915A2.478 2.478 0 0119.494 23H4.503a2.478 2.478 0 01-2.15-1.224c-.455-.767-.472-1.691-.026-2.511L9.804 2.302A2.465 2.465 0 0111.998 1c.929 0 1.75.487 2.176 1.266l7.514 17.037a2.48 2.48 0 01-.044 2.473z" />
  </svg>
</template>

<script>
export default {
  name: 'Attention'
}
</script>
