
import {defineComponent, computed} from "vue";

export default defineComponent({
  name: 'InputText',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text'
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  setup(props) {
    const displayLabel = computed(() => {
      return `${props.label}${props.required ? '*' : ''}`;
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const focusOutOverwrite = () => {};

    return {
      displayLabel,
      focusOutOverwrite,
    }
  }})
