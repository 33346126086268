<template>
  <div class="app-grid">
    <div
      v-if="mq['2xl']"
      class="app-left"
    >
      <img
        v-for="i in 8"
        :key="i"
        :src="`https://aquanautic.herweg.co/images/contact-left-0${i}.jpg`"
        alt=""
        class="mb-6 w-full object-cover lazyload"
      >
    </div>
    <div class="mx-auto py-9 px-4 md:py-16 md:px-16 lg:px-24  bg-white">
      <h1 class="text-xl md:text-2xl lg:text-4xl font-semibold mb-10">
        Das nächste Abenteuer wartet auf Sie. <span class="text-brand-600 md:block md:font-normal"> Planen Sie Ihren Urlaub mit Aquanautic Elba.</span>
      </h1>

      <form
        action="#"
        @submit.prevent="submitForm"
      >
        <section class="mb-12">
          <h4 class="font-bold mb-1">
            Ihre persönlichen Informationen
          </h4>
          <p class="mb-5">
            Bitte geben Sie Ihren Namen und Ihre E-Mail-Adresse an, damit wir Ihnen ein unverbindliches Angebot zuschicken können.
          </p>

          <fieldset class="flex flex-wrap md:-mr-3 mb-12">
            <InputText
              id="firstName"
              v-model="firstName"
              label="Vorname"
              :required="true"
              :error="fieldHasError('firstName')"
            />

            <InputText
              id="lastName"
              v-model="lastName"
              label="Nachname"
            />

            <InputText
              id="phone"
              v-model="phone"
              label="Telefon"
            />

            <InputText
              id="email"
              v-model="email"
              label="Email"
              input-type="email"
              :required="true"
              :error="fieldHasError('email')"
            />

            <div
              v-if="fieldHasError('firstName') || fieldHasError('email')"
              class="w-full mr-3"
            >
              <p
                v-if="fieldHasError('firstName')"
                class="bg-red-100 ring ring-1 w-full ring-red-500 text-red-900 py-2 px-4 rounded mt-6 flex items-center"
              >
                <Attention class="mr-4 w-4 h-4" /> {{ formErrors['firstName'] }}
              </p>
              <p
                v-if="fieldHasError('email')"
                class="bg-red-100 ring ring-1 w-full ring-red-500 text-red-900 py-2 px-4 rounded mt-6 flex items-center"
              >
                <Attention class="mr-4 w-4 h-4" /> {{ formErrors['email'] }}
              </p>
            </div>
          </fieldset>

          <hr class="b-t border-primary-100 w-11/12 mx-auto">
        </section>

        <section class="mb-14">
          <h4 class="font-bold mb-1">
            Ihr Urlaub
          </h4>
          <p class="mb-5">
            Auf Elba verbringen Sie das ganze Jahr über einen unvergesslichen Urlaub - alleine, zu zweit oder mit der
            ganzen Familie.
          </p>

          <fieldset class="flex flex-wrap md:-mr-3 mb-12">
            <InputSelect
              id="numberOfAdults"
              v-model="numberOfAdults"
              class="md:w-1/3"
              label="Anzahl Erwachsene"
              :options="numberOfAdultsOptions"
            />

            <InputSelect
              id="numberOfChildren"
              v-model="numberOfChildren"
              class="md:w-1/3"
              label="Anzahl Kinder"
              :options="numberOfChildrenOptions"
            />

            <InputSelect
              id="numberOfDiver"
              v-model="numberOfDivers"
              class="md:w-1/3"
              label="Davon Taucher"
              :options="numberOfDiversOptions"
            />

            <div class="w-full flex flex-wrap">
              <label
                class="block flex-auto w-full md:w-1/2 md:pr-3 mb-3"
                for="checkIn"
              >
                <span class="block text-primary-200 mb-2">Anreise</span>
                <datepicker
                  id="check-in"
                  v-model="checkIn"
                  :date-min="minCheckIn"
                />
              </label>
              <label
                class="block flex-auto w-full md:w-1/2 md:pr-3 mb-3"
                for="checkOut"
              >
                <span class="block text-primary-200 mb-2">Anreise</span>
                <datepicker
                  id="checkOut"
                  v-model="checkOut"
                  :date-min="checkIn"
                />
              </label>
            </div>
          </fieldset>

          <p class="mt-12 mb-6">
            Gerne unterstützen wir Sie bei der Reservierung eines Appartements, der Planung Ihrer An- und Abreise, sowie
            der Kinderbetreuung. <span class="font-bold italic">Für alle Gäste unserer Apartmentanlagen bieten wir parallel zu den Tauchausfahrten exklusiv unseren Aquanautic Elba KidsClub an.</span>
          </p>

          <fieldset class="mb-12">
            <span class="block text-primary-200 mb-2">Reise und Unterkunft</span>
            <div
              class="grid grid-cols-2 md:grid-cols-4 gap-3"
            >
              <div
                v-for="option in apartmentOptions"
                class="aspect-h-13 aspect-w-16"
              >
                <label
                  :for="option.id"
                  class="absolute w-full h-full bg-primary-50 hover:bg-primary-100 rounded cursor-pointer flex flex-col items-center justify-between px-3 pb-4 pt-5 text-center border font-bold"
                  :class="{
                    'bg-brand-400 border-primary-500 text-primary-500 hover:bg-brand-600': apartmentOptionsModel.includes(option.id),
                    'border-primary-200 text-primary-300': !apartmentOptionsModel.includes(option.id)
                  }"
                >
                  <Checkmark
                    v-show="apartmentOptionsModel.includes(option.id)"
                    class="w-5 h-5 absolute -top-2 -right-2"
                  />
                  <input
                    :id="option.id"
                    v-model="apartmentOptionsModel"
                    :name="option.id"
                    :value="option.id"
                    type="checkbox"
                    class="invisible absolute w-full h-full"
                  >
                  <component
                    :is="option.icon"
                    class="w-17 h-17 fill-current"
                  />
                  <span class="text-sm">{{ option.label }}</span>
                </label>
              </div>
            </div>
          </fieldset>
        </section>

        <section class="mb-12 bg-primary-50 -mx-4 md:-mx-16 lg:-mx-24 py-8 md:py-12 px-4 md:px-16 lg:px-24">
          <h4 class="font-bold mb-1">
            Ihre Tauchinteressen
          </h4>
          <p class="mb-5">
            Das Mittelmeer rund um Elba bietet spektakuläre Tauchplätze für erfahrene Taucher und alle, die ihren ersten Tauchkurs machen möchten. Lassen Sie uns wissen, woran Sie interessiert sind.
          </p>

          <fieldset>
            <span class="block text-primary-200 mb-2">Interessen</span>
            <div
              class="grid grid-cols-2 md:grid-cols-4 gap-3"
            >
              <div
                v-for="option in interests"
                :key="option.id"
                class="aspect-h-13 aspect-w-16"
              >
                <label
                  :for="option.id"
                  class="absolute w-full h-full bg-white rounded cursor-pointer flex flex-col items-center justify-between px-3 pb-4 pt-5 text-center border font-bold"
                  :class="{
                    'bg-brand-400 border-primary-500 text-primary-500 hover:bg-brand-600': interestsModel.includes(option.id),
                    'border-primary-200 text-primary-300 hover:bg-primary-50': !interestsModel.includes(option.id)
                  }"
                >
                  <Checkmark
                    v-show="interestsModel.includes(option.id)"
                    class="w-5 h-5 absolute -top-2 -right-2"
                  />
                  <input
                    :id="option.id"
                    v-model="interestsModel"
                    :name="option.id"
                    :value="option.id"
                    type="checkbox"
                    class="invisible absolute w-full h-full"
                  >
                  <component
                    :is="option.icon"
                    class="w-17 h-17 fill-current"
                  />
                  <span class="text-sm">{{ option.label }}</span>
                </label>
              </div>
            </div>
          </fieldset>
        </section>

        <section class="mb-12">
          <h4 class="font-bold mb-1">
            Ihr Freizeitprogramm
          </h4>
          <p class="mb-5">
            Machen Sie aus Ihrem Tauchurlaub einen Erlebnisurlaub und erkunden Sie Elba bei Frezeitaktivitäten, die zu Ihnen passen.
          </p>

          <fieldset class="mb-12">
            <span class="block text-primary-200 mb-2">Reise und Unterkunft</span>
            <div
              class="grid grid-cols-2 md:grid-cols-4 gap-3"
            >
              <div
                v-for="option in programs"
                :key="option.id"
                class="aspect-h-13 aspect-w-16"
              >
                <label
                  :for="option.id"
                  class="absolute w-full h-full bg-primary-50 hover:bg-primary-100 rounded cursor-pointer flex flex-col items-center justify-between px-3 pb-4 pt-5 text-center border font-bold"
                  :class="{
                    'bg-brand-400 border-primary-500 text-primary-500 hover:bg-brand-600': programsModel.includes(option.id),
                    'border-primary-200 text-primary-300': !programsModel.includes(option.id)
                  }"
                >
                  <Checkmark
                    v-show="programsModel.includes(option.id)"
                    class="w-5 h-5 absolute -top-2 -right-2"
                  />
                  <input
                    :id="option.id"
                    v-model="programsModel"
                    :name="option.id"
                    :value="option.id"
                    type="checkbox"
                    class="invisible absolute w-full h-full"
                  >
                  <component
                    :is="option.icon"
                    class="w-17h-17 fill-current"
                  />
                  <span class="text-sm">{{ option.label }}</span>
                </label>
              </div>
            </div>
          </fieldset>

          <hr class="b-t border-primary-100 w-11/12 mx-auto">
        </section>

        <section class="mb-12">
          <h4 class="font-bold mb-1">
            Ihre Nachricht
          </h4>
          <p class="mb-5">
            Hier können Sie weitere Angaben zu Ihrem Aufenthalt auf Elba machen wie z.B. Langzeitaufenthalt, Elba im Winter, Haustiere, Leihausrüstung, AquaMiles Bonusprogramm, Sprach- und Kochkurse
          </p>

          <fieldset class="flex flex-wrap md:-mr-3 mb-12">
            <label
              class="block flex-auto w-full md:pr-3 mb-3"
              for="message"
            >
              <span class="block text-primary-200 mb-2">Nachricht</span>
              <textarea
                id="message"
                v-model="message"
                name="message"
                rows="10"
                class="bg-primary-50 p-3 rounded border-primary-200 block w-full"
              />
            </label>

            <label
              class="flex"
              for="tnbs"
            >
              <input
                id="tnbs"
                v-model="tnbs"
                name="tnbs"
                type="checkbox"
                class="mr-3"
                :class="{
                  'ring ring-red-500': fieldHasError('tnbs')
                }"
              >
              <div class="italic text-xs text-primary-t">Ich willige ein, dass meine hier eingebenen Daten gemäß unseren <a
                href="#"
                class="underline"
              >Datenschutzbestimmungen</a> zur Beantwortung meiner Anfrage vorübergehend verwendet und gespeichert werden.</div>
            </label>
            <div
              v-if="fieldHasError('tnbs')"
              class="bg-red-100 ring ring-1 w-full mr-3 ring-red-500 text-red-900 py-2 px-4 rounded mt-6 flex items-center"
            >
              <Attention class="mr-4 w-4 h-4" /> {{ formErrors['tnbs'] }}
            </div>
          </fieldset>
        </section>

        <section class="flex items-center">
          <button
            type="submit"
            class="rounded border font-bold px-6 py-4"
            :disabled="state.matches('submitting')"
            :class="{
              'bg-brand-400 border-transparent hover:bg-brand-500 text-primary-500': !state.matches('submitting'),
              'bg-primary-50 border-primary-200 text-primary-500': state.matches('submitting'),

            }"
          >
            <span v-show="state.matches('idle')">Unverbindliches Angebot anfordern</span>
            <span v-show="state.matches('submitting')">Anfrage wird gesendet</span>
            <span
              v-if="state.matches('submitted')"
              class="flex items-center justify-center"
            ><CheckmarkSimple class="mr-1.5 w-5 h-5" />Erfolgreich gesendet!</span>
            <span v-show="state.matches('failed')">Ups! Leider ist etwas schief gegangen, probier es noch einmal.</span>
          </button>
          <Loading
            v-if="state.matches('submitting')"
            class="w-8 h-8 ml-4 animate-spin"
          />
        </section>
      </form>
    </div>
    <div
      v-if="mq['2xl']"
      class="app-right"
    >
      <img
        v-for="i in 8"
        :key="i"
        :src="`https://aquanautic.herweg.co/images/contact-right-0${i}.jpg`"
        class="mb-6 w-full object-cover lazyload"
        alt="Aquanautic Contact Form"
      >
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineAsyncComponent, defineComponent, reactive, ref} from "vue";
import Datepicker from "./Datepicker.vue";
import Checkmark from "./svgs/checkmark.vue";
import InputSelect from "./InputSelect.vue";
import InputText from "./InputText.vue";
import {useMq} from "./composables/mq";
import DivingSafari from "./svgs/divingSafari.vue";
import useFormMachine from "./store";
import Attention from "./svgs/attention.vue";
import Loading from "./svgs/loading.vue";
import CheckmarkSimple from "./svgs/checkmark-simple.vue";

export default defineComponent({
  name: 'Form',
  components: {CheckmarkSimple, Loading, Attention, Datepicker, Checkmark, InputSelect, InputText},
  setup() {
    const { send, state } = useFormMachine();
    const { mq } = useMq();
    const firstName = ref('');
    const lastName = ref('');
    const phone = ref('');
    const email = ref('');
    const arrayInstance = [0,1,2,3,4,5,6,7,8,9];
    const numberOfAdultsOptions = arrayInstance.map((_, index) => {
      if (index === 0) {
        return '1 Erwachsener'
      }

      return `${index + 1} Erwachsene`;
    });
    const numberOfAdults = ref(numberOfAdultsOptions[0]);

    const numberOfChildrenOptions = [...arrayInstance.map((_, index) => {
      if (index === 1) {
        return '1 Kind'
      }

      return `${index} Kinder`;
    }), '10 Kinder'];
    const numberOfChildren = ref(numberOfChildrenOptions[0]);

    const combinedAdultsAndChilds = computed(() => {
      return parseInt(numberOfAdults.value, 10) + parseInt(numberOfChildren.value, 10);
    });
    const numberOfDiversOptions = computed(() => {
      return new Array(combinedAdultsAndChilds.value + 1).fill(undefined).map((_, index) => index.toString());
    })
    const numberOfDivers = ref(numberOfDiversOptions.value[0]);

    const apartmentOptions = [
      {
        icon: '',
        label: 'Apartment',
        id: 'apartment'
      },
      {
        icon: '',
        label: 'Fähre nach Elba',
        id: 'ferry'
      },
      {
        icon: '',
        label: 'Flughafentransfer (Pisa)',
        id: 'airportTransfer'
      },
      {
        icon: '',
        label: 'KidsClub',
        id: 'kidsClub'
      },
      {
        icon: '',
        label: 'Roller/Scooter',
        id: 'scooter'
      },
      {
        icon: '',
        label: 'E-Bikes',
        id: 'eBikes'
      },
      {
        icon: '',
        label: 'CO2 Kompensation der Anreise',
        id: 'cO2'
      },
      {
        icon: '',
        label: 'Frühstück/Abendessen',
        id: 'meals'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
          errorComponent: ''
        }),
      }
    });

    const apartmentOptionsModel = ref([]);

    const interests = [
      {
        icon: '',
        label: 'Vieltauchen',
        id: 'diving'
      },
      {
        icon: '',
        label: 'Kindertauchen',
        id: 'kidsDiving'
      },
      {
        icon: '',
        label: 'Tauchkurse',
        id: 'divingCourses'
      },
      {
        icon: '',
        label: 'Techniches Tauchen',
        id: 'technicalDiving'
      },
      {
        icon: '',
        label: 'Tauchen als Beruf',
        id: 'divingAsJob'
      },
      {
        icon: '',
        label: 'Tauchsafari',
        id: 'divingSafari'
      },
      {
        icon: '',
        label: 'Apnoe/Freitauchen',
        id: 'apnoeDiving'
      },
      {
        icon: '',
        label: 'Forschungstauchen',
        id: 'divingExplore'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
        }),
      }
    });

    const interestsModel = ref([]);

    const programs = [
      {
        icon: '',
        label: 'Hiking/Wandern',
        id: 'hiking'
      },
      {
        icon: '',
        label: 'Sightseeing',
        id: 'sightseeing'
      },
      {
        icon: '',
        label: 'Reiten',
        id: 'equestrian'
      },
      {
        icon: '',
        label: 'Motorboot',
        id: 'motorboat'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
          errorComponent: ''
        }),
      }
    });

    const programsModel = ref([]);

    const message = ref('');

    const checkIn = ref('');

    const checkOut = ref('');

    const tnbs = ref(false);

    const validateForm = () => {
      return new Promise((resolve, reject) => {
        const errors = {};
        if (firstName.value.length === 0 || email.value.length === 0 || !tnbs.value) {
          if (firstName.value.length === 0) {
            errors['firstName'] = 'Bitte gib deinen Vornamen ein';
          } else {
            errors['firstName'] = '';
          }

          if (email.value.length === 0) {
            errors['email'] = 'Bitte gib deine Email-Adresse ein';
          } else {
            errors['email'] = '';
          }

          if (!tnbs.value) {
            errors['tnbs'] = 'Du musst den Datenschutzbedingungen zustimmen';
          } else {
            errors['tnbs'] = '';
          }

          return reject(errors);
        }
        return resolve('Form validated');
      });
    }

    interface FormErrors {
      firstName: string,
      email: string,
      tnbs: string,
    }

    const formErrors = reactive<FormErrors>({
      firstName: '',
      email: '',
      tnbs: '',
    });

    const submitForm = async () =>{
      try {
        await validateForm()

        send({
          type: 'SUBMIT',
          data: {
            lastName: lastName.value,
            firstName: firstName.value,
            phone: phone.value,
            email: email.value,
            checkIn: checkIn.value,
            checkOut: checkOut.value,
            adults: numberOfAdults.value,
            kids: numberOfChildren.value,
            divers: numberOfDivers.value.toString(),
            travelAndAccomodations: apartmentOptionsModel.value.join(', '),
            divingInterests: interestsModel.value.join(', '),
            freeTimeProgram: programsModel.value.join(', '),
            message: message.value
          }
        })
      } catch (e) {
        Object.entries(e).forEach(([key, value]) => {
          formErrors[key] = value;
        })
      }
    };

    const fieldHasError = (field: string) => {
      return formErrors[field].length > 0;
    }

    const minCheckIn = computed(() => {
      const date = new Date().toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return date;
    });

    return {
      firstName,
      lastName,
      phone,
      email,
      numberOfAdults,
      numberOfAdultsOptions,
      numberOfChildrenOptions,
      numberOfChildren,
      numberOfDiversOptions,
      numberOfDivers,
      apartmentOptions,
      apartmentOptionsModel,
      interests,
      interestsModel,
      programs,
      programsModel,
      mq,
      send,
      state,
      submitForm,
      checkIn,
      checkOut,
      message,
      tnbs,
      formErrors,
      fieldHasError,
      minCheckIn,
    }
  }
})
</script>
