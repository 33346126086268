
import '@duetds/date-picker/dist/duet/themes/default.css'
import {defineComponent, onMounted, ref} from "vue";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: 'Datepicker',
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true,
    },
    dateMin: {
      type: String,
      default: ''
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const datepicker = ref<HTMLDuetDatePickerElement>(null);
    const value = ref('');
    const intermediateValue = ref('');

    onMounted(() => {
      datepicker.value.addEventListener('duetChange', (e: CustomEvent<{ component: "duet-date-picker"; valueAsDate: Date; value: string }>) => {
        value.value = e.detail.value
        emit('update:modelValue', e.detail.value)
      });

      datepicker.value.addEventListener('duetBlur', (e: CustomEvent<{ component: "duet-date-picker" }>) => {
        emit('update:modelValue', intermediateValue.value);
      });

      const DATE_FORMAT = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/

      datepicker.value.dateAdapter = {
        parse(value = "", createDate) {
          const matches = value.match(DATE_FORMAT)
          if (matches) {
            return createDate(matches[3], matches[2], matches[1])
          } else {
            try {
              const parsedDate = dayjs(value);
              if (parsedDate.isSameOrAfter(dayjs())) {
                intermediateValue.value = parsedDate.format('YYYY-MM-DD');
              }
            } catch (e) {
             console.error(e);
            }
          }
        },
        format(date) {
          return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
        },
      }

      datepicker.value.localization = {
        buttonLabel: "Valitse päivämäärä",
        placeholder: "dd.mm.yyyy",
        selectedDateMessage: "Valittu päivämäärä on",
        prevMonthLabel: "Vorheriger Monat",
        nextMonthLabel: "Nächster Monat",
        monthSelectLabel: "Monat auswählen",
        yearSelectLabel: "Jahr auswählen",
        closeLabel: "Schliessen",
        keyboardInstruction: "Keyboard Instructions",
        calendarHeading: "Valitse päivämäärä",
        dayNames: [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthNamesShort: ["Jan", "Feb", "März", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        locale: "de-DE",
      }
    });

    return {
      value,
      datepicker,
    }
  }
})
