
import {defineComponent, onMounted} from "vue";
import SuccessGraphic from "./SuccessGraphic.vue";
import useFormMachine from "./store";
import axios from "axios";
import qs from 'qs';

export default defineComponent({
  name: 'Success',
  components: {SuccessGraphic},
})
