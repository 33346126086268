<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#193A55"
      stroke-width="4"
      d="M13 24.184l9.24 7.935L34.207 15"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckmarkSimple'
}
</script>
