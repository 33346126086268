
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: 'InputSelect',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    function handleUpdate(value: any) {
      emit('update:modelValue', value.target.value);
    }

    const displayLabel = computed(() => {
      return `${props.label}${props.required ? '*' : ''}`;
    });

    return {
      handleUpdate,
      displayLabel,
    }
  }
})
