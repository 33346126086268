<template>
  <label
    class="block flex-auto w-full md:w-1/2 md:pr-3 mb-3"
    :for="id"
  >
    <span class="block text-primary-200 mb-2">{{ displayLabel }}</span>
    <select
      :id="id"
      :name="id"
      class="bg-primary-50 p-3 rounded border-primary-200 block w-full text-primary-200 text-sm"
      :value="modelValue"
      @change="handleUpdate"
    >
      <option
        v-for="option in options"
        :key="option"
        :value="option"
      >{{ option }}</option>
    </select>
  </label>
</template>
<script lang="ts">
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: 'InputSelect',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    function handleUpdate(value: any) {
      emit('update:modelValue', value.target.value);
    }

    const displayLabel = computed(() => {
      return `${props.label}${props.required ? '*' : ''}`;
    });

    return {
      handleUpdate,
      displayLabel,
    }
  }
})
</script>
