<template>
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <g fill="#1f4463">
      <path d="M54.8 25.4c0 2.2-1.8 3.9-3.9 3.9-2.2 0-3.9-1.8-3.9-3.9V8.9C47 6.7 48.8 5 50.9 5c2.2 0 3.9 1.8 3.9 3.9v16.5z" />
      <path
        d="M73.9 41.5c-1.9 1.1-4.3.4-5.4-1.4-1.1-1.9-.4-4.3 1.4-5.4l14.3-8.3c1.9-1.1 4.3-.4 5.4 1.4 1.1 1.9.4 4.3-1.4 5.4l-14.3 8.3z"
        opacity=".1"
      />
      <path
        d="M75 54.3c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9h16.5c2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9H75z"
        opacity=".2"
      />
      <path
        d="M69.5 66c-1.9-1.1-2.5-3.5-1.4-5.4 1.1-1.9 3.5-2.5 5.4-1.4l14.3 8.3c1.9 1.1 2.5 3.5 1.4 5.4-1.1 1.9-3.5 2.5-5.4 1.4L69.5 66z"
        opacity=".3"
      />
      <path
        d="M58.9 73.4c-1.1-1.9-.4-4.3 1.4-5.4 1.9-1.1 4.3-.4 5.4 1.4L74 83.7c1.1 1.9.4 4.3-1.4 5.4-1.9 1.1-4.3.4-5.4-1.4l-8.3-14.3z"
        opacity=".4"
      />
      <path
        d="M46 74.5c0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9V91c0 2.2-1.8 3.9-3.9 3.9-2.2 0-3.9-1.8-3.9-3.9V74.5z"
        opacity=".5"
      />
      <path
        d="M34.3 69c1.1-1.9 3.5-2.5 5.4-1.4 1.9 1.1 2.5 3.5 1.4 5.4l-8.3 14.3c-1.1 1.9-3.5 2.5-5.4 1.4-1.9-1.1-2.5-3.5-1.4-5.4L34.3 69z"
        opacity=".58"
      />
      <path
        d="M26.9 58.4c1.9-1.1 4.3-.4 5.4 1.4 1.1 1.9.4 4.3-1.4 5.4l-14.3 8.3c-1.9 1.1-4.3.4-5.4-1.4-1.1-1.9-.4-4.3 1.4-5.4l14.3-8.3z"
        opacity=".66"
      />
      <path
        d="M25.8 45.6c2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9H9.3c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9h16.5z"
        opacity=".74"
      />
      <path
        d="M31.3 33.9c1.9 1.1 2.5 3.5 1.4 5.4-1.1 1.9-3.5 2.5-5.4 1.4L13 32.4c-1.9-1.1-2.5-3.5-1.4-5.4 1.1-1.9 3.5-2.5 5.4-1.4l14.3 8.3z"
        opacity=".82"
      />
      <path
        d="M41.9 26.5c1.1 1.9.4 4.3-1.4 5.4-1.9 1.1-4.3.4-5.4-1.4l-8.3-14.3c-1.1-1.9-.4-4.3 1.4-5.4 1.9-1.1 4.3-.4 5.4 1.4l8.3 14.3z"
        opacity=".9"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
