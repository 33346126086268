
import {computed, defineAsyncComponent, defineComponent, reactive, ref} from "vue";
import Datepicker from "./Datepicker.vue";
import Checkmark from "./svgs/checkmark.vue";
import InputSelect from "./InputSelect.vue";
import InputText from "./InputText.vue";
import {useMq} from "./composables/mq";
import DivingSafari from "./svgs/divingSafari.vue";
import useFormMachine from "./store";
import Attention from "./svgs/attention.vue";
import Loading from "./svgs/loading.vue";
import CheckmarkSimple from "./svgs/checkmark-simple.vue";

export default defineComponent({
  name: 'Form',
  components: {CheckmarkSimple, Loading, Attention, Datepicker, Checkmark, InputSelect, InputText},
  setup() {
    const { send, state } = useFormMachine();
    const { mq } = useMq();
    const firstName = ref('');
    const lastName = ref('');
    const phone = ref('');
    const email = ref('');
    const arrayInstance = [0,1,2,3,4,5,6,7,8,9];
    const numberOfAdultsOptions = arrayInstance.map((_, index) => {
      if (index === 0) {
        return '1 Erwachsener'
      }

      return `${index + 1} Erwachsene`;
    });
    const numberOfAdults = ref(numberOfAdultsOptions[0]);

    const numberOfChildrenOptions = [...arrayInstance.map((_, index) => {
      if (index === 1) {
        return '1 Kind'
      }

      return `${index} Kinder`;
    }), '10 Kinder'];
    const numberOfChildren = ref(numberOfChildrenOptions[0]);

    const combinedAdultsAndChilds = computed(() => {
      return parseInt(numberOfAdults.value, 10) + parseInt(numberOfChildren.value, 10);
    });
    const numberOfDiversOptions = computed(() => {
      return new Array(combinedAdultsAndChilds.value + 1).fill(undefined).map((_, index) => index.toString());
    })
    const numberOfDivers = ref(numberOfDiversOptions.value[0]);

    const apartmentOptions = [
      {
        icon: '',
        label: 'Apartment',
        id: 'apartment'
      },
      {
        icon: '',
        label: 'Fähre nach Elba',
        id: 'ferry'
      },
      {
        icon: '',
        label: 'Flughafentransfer (Pisa)',
        id: 'airportTransfer'
      },
      {
        icon: '',
        label: 'KidsClub',
        id: 'kidsClub'
      },
      {
        icon: '',
        label: 'Roller/Scooter',
        id: 'scooter'
      },
      {
        icon: '',
        label: 'E-Bikes',
        id: 'eBikes'
      },
      {
        icon: '',
        label: 'CO2 Kompensation der Anreise',
        id: 'cO2'
      },
      {
        icon: '',
        label: 'Frühstück/Abendessen',
        id: 'meals'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
          errorComponent: ''
        }),
      }
    });

    const apartmentOptionsModel = ref([]);

    const interests = [
      {
        icon: '',
        label: 'Vieltauchen',
        id: 'diving'
      },
      {
        icon: '',
        label: 'Kindertauchen',
        id: 'kidsDiving'
      },
      {
        icon: '',
        label: 'Tauchkurse',
        id: 'divingCourses'
      },
      {
        icon: '',
        label: 'Techniches Tauchen',
        id: 'technicalDiving'
      },
      {
        icon: '',
        label: 'Tauchen als Beruf',
        id: 'divingAsJob'
      },
      {
        icon: '',
        label: 'Tauchsafari',
        id: 'divingSafari'
      },
      {
        icon: '',
        label: 'Apnoe/Freitauchen',
        id: 'apnoeDiving'
      },
      {
        icon: '',
        label: 'Forschungstauchen',
        id: 'divingExplore'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
        }),
      }
    });

    const interestsModel = ref([]);

    const programs = [
      {
        icon: '',
        label: 'Hiking/Wandern',
        id: 'hiking'
      },
      {
        icon: '',
        label: 'Sightseeing',
        id: 'sightseeing'
      },
      {
        icon: '',
        label: 'Reiten',
        id: 'equestrian'
      },
      {
        icon: '',
        label: 'Motorboot',
        id: 'motorboat'
      },
    ].map(option => {
      return {
        ...option,
        icon: defineAsyncComponent({
          loader: () => import((`./svgs/${option.id}.vue`)),
          timeout: 3000,
          errorComponent: ''
        }),
      }
    });

    const programsModel = ref([]);

    const message = ref('');

    const checkIn = ref('');

    const checkOut = ref('');

    const tnbs = ref(false);

    const validateForm = () => {
      return new Promise((resolve, reject) => {
        const errors = {};
        if (firstName.value.length === 0 || email.value.length === 0 || !tnbs.value) {
          if (firstName.value.length === 0) {
            errors['firstName'] = 'Bitte gib deinen Vornamen ein';
          } else {
            errors['firstName'] = '';
          }

          if (email.value.length === 0) {
            errors['email'] = 'Bitte gib deine Email-Adresse ein';
          } else {
            errors['email'] = '';
          }

          if (!tnbs.value) {
            errors['tnbs'] = 'Du musst den Datenschutzbedingungen zustimmen';
          } else {
            errors['tnbs'] = '';
          }

          return reject(errors);
        }
        return resolve('Form validated');
      });
    }

    interface FormErrors {
      firstName: string,
      email: string,
      tnbs: string,
    }

    const formErrors = reactive<FormErrors>({
      firstName: '',
      email: '',
      tnbs: '',
    });

    const submitForm = async () =>{
      try {
        await validateForm()

        send({
          type: 'SUBMIT',
          data: {
            lastName: lastName.value,
            firstName: firstName.value,
            phone: phone.value,
            email: email.value,
            checkIn: checkIn.value,
            checkOut: checkOut.value,
            adults: numberOfAdults.value,
            kids: numberOfChildren.value,
            divers: numberOfDivers.value.toString(),
            travelAndAccomodations: apartmentOptionsModel.value.join(', '),
            divingInterests: interestsModel.value.join(', '),
            freeTimeProgram: programsModel.value.join(', '),
            message: message.value
          }
        })
      } catch (e) {
        Object.entries(e).forEach(([key, value]) => {
          formErrors[key] = value;
        })
      }
    };

    const fieldHasError = (field: string) => {
      return formErrors[field].length > 0;
    }

    const minCheckIn = computed(() => {
      const date = new Date().toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return date;
    });

    return {
      firstName,
      lastName,
      phone,
      email,
      numberOfAdults,
      numberOfAdultsOptions,
      numberOfChildrenOptions,
      numberOfChildren,
      numberOfDiversOptions,
      numberOfDivers,
      apartmentOptions,
      apartmentOptionsModel,
      interests,
      interestsModel,
      programs,
      programsModel,
      mq,
      send,
      state,
      submitForm,
      checkIn,
      checkOut,
      message,
      tnbs,
      formErrors,
      fieldHasError,
      minCheckIn,
    }
  }
})
