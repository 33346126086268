<template>
  <label
    class="block flex-auto w-full md:w-1/2 md:pr-3 mb-3"
    :for="id"
  >
    <span class="block text-primary-200 mb-2">{{ displayLabel }}</span>
    <input
      :id="id"
      :name="id"
      :type="inputType"
      class="bg-primary-50 p-3 rounded border-primary-200 block w-full"
      :class="{
        'ring ring-red-500': error,
      }"
      :value="modelValue"
      data-holder=""
      @focusout="focusOutOverwrite"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </label>
</template>
<script lang="ts">
import {defineComponent, computed} from "vue";

export default defineComponent({
  name: 'InputText',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text'
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  setup(props) {
    const displayLabel = computed(() => {
      return `${props.label}${props.required ? '*' : ''}`;
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const focusOutOverwrite = () => {};

    return {
      displayLabel,
      focusOutOverwrite,
    }
  }})
</script>
